/* eslint-disable */
import React, { Fragment } from 'react';
import playarrow from '../../img/play-arrow.svg';

const AboutUsSection = props => {
    const { pic, name, descrip, href } = props.serv;

    return (
        <Fragment>
            <div className="vertical-line"></div>
            <div className="vertical-line-25"></div>
            <a
                href={href}
                className="card-news card-news-click-area"
                data-ix="line-arrow"
                style={{ transition: 'all 0.2s ease 0s' }}
            >
                <div className="p-class">
                    <div className="wp-block-image">
                        <figure className="aligncenter is-resized">
                            <img
                                loading="lazy"
                                src={`http://admin.argus-eko.ru/wp-content/uploads/2020/12/${pic}`}
                                alt=""
                                className="wp-image-665"
                                width="143"
                                height="143"
                                sizes="(max-width: 143px) 100vw, 143px"
                            />
                        </figure>
                    </div>
                </div>
                <h3>{name}</h3>
                <div className="p-class">
                    <p>{descrip}</p>
                </div>
                <div className="link w-inline-block">
                    <div>Узнать подробнее</div>
                    <div className="before-txt-link">
                        <div className="fon-arrow">
                            <img src={playarrow} alt="" className="arrow-line" />
                            <div className="line-arrow"></div>
                        </div>
                    </div>
                </div>
            </a>
        </Fragment>
    );
};

export default AboutUsSection;
