/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import playarrow from '../../../../img/play-arrow.svg';
import Icon from './StudingChildItemIcon';

export class StudingChildItem extends Component {
    componentDidUpdate() {
        Webflow.destroy();
        Webflow.ready();
    }

    render() {
        const { izob, title, excerpt, slug } = this.props;

        return (
            <Fragment>
                <Link 
                    to={`/training-and-seminars/${slug}`}
                    className="card-news news-edits card-news-click-area"
                    data-ix="line-arrow"
                >
                    {izob ? <Icon url={izob.url} /> : null}
                    <h3>{title}</h3>
                    <div className="p-class">{excerpt}</div>
                    <div className="link w-inline-block">
                        <div>Узнать подробнее</div>
                        <div className="before-txt-link">
                            <div className="fon-arrow">
                                <img src={playarrow} alt="" className="arrow-line" />
                                <div className="line-arrow"></div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Fragment>
        );
    }
}

export default StudingChildItem;
